.bm-burger-button {
  position: fixed;
  display: none;
  width: 20px;
  height: 20px;
  right: 35px;
  top: 20px;
}
@media (min-width: 992px) {
  .bm-burger-button {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .bm-burger-button {
    right: 65px;
    display: block;
  }
}
@media (max-width: 600px) {
  .bm-burger-button {
    display: block;
  }
}

.bm-burger-bars {
  background: #000;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #000;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: #f7f4f4;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #c9b7b7;
}

.bm-item-list {
  color: #b8b7ad;
  text-align: center;
  padding: 3.8em;
}

.bm-item {
  padding-top: 40px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  display: inline-block;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.munu {
  margin-right: 62px;
  margin-top: 17.4px;
}
@media (min-width: 992px) {
  .munu {
    display: none !important;
  }
}
