@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Cabin+Sketch:wght@400;700&family=Dancing+Script:wght@400;500;600;700&family=Dosis:wght@200;300;400;500;600;700;800&family=Handjet:wght@100;200;300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.w-100 {
  height: 80vh;
  object-fit: cover;
}
.Carousel {
  position: relative;
}
.first {
  position: absolute;
  top: 39%;
  right: 50%;
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  width: 100%;
}
.jp {
  /* background-color: orange; */
  background: rgba(0, 0, 0, 0.4);
  padding: 5px;
}
.first2 {
  position: absolute;
  top: 39%;
  left: -11%;
  text-align: right;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  width: 100%;
}
.first3 {
  /* margin-bottom: 165px; */
  background: rgba(0, 0, 0, 0.6);
  border-radius: 15px;

  padding: 42px 0px;
  text-align: center;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.tex {
  position: absolute;
  width: 100%;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 600px) {
  .tex {
    position: absolute;
    width: 100%;
    top: 69%;
    /* padding: 0px 12px !important; */
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .w-100 {
    height: 83.9vh !important;
  }
  .carousel-control-next-icon {
    background-color: rgb(31, 30, 30);
    padding: 10px !important;
    margin-top: 30px !important;
  }
  .carousel-control-prev-icon {
    background-color: rgb(31, 30, 30);
    padding: 10px !important;
    margin-top: 30px !important;
  }

  .first {
    position: absolute;
    right: 0;
    text-align: center;
    left: 0;
    top: 28%;
  }

  .f1 {
    font-size: 24px !important;
  }
  .f2 {
    font-size: 25px !important;
    line-height: 37px;
  }
  .first2 {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 32%;
  }
  /* .first3 {
    position: absolute;
    top: 35%;
  } */
  .first3 h1 {
    font-size: 22px !important;
  }

  .mobile-the {
    font-size: 22px !important;
    margin-top: 52px;
    margin-bottom: 27px;
  }
  .kk {
    font-size: 17px !important;
    margin-bottom: -22px;
  }
}
/* Hero.css */

.gradient-overlay {
  position: relative;
}

.gradient-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3)
  ); */
}
.filter {
  perspective: 20rem;
  border: none;
  background-color: transparent;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 30px;
  box-shadow: 0 5px 15px rgba(128, 0, 128, 0.308);
  border-radius: 10px !important;
  outline: none;
  color: white;
}
.filter::before {
  content: "";
  position: absolute;
  border-radius: 10px;
  background-color: #6f4a37;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transform: rotate(0deg);
  transition: transform 1.5s;
}

.filter:hover::before {
  transform: rotate(180deg);
}
.zoom {
  transition: transform 1s ease-in-out;
}
.card1:hover .zoom {
  transform: scale(1.1);
}
.card1 {
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  transition: 1s;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.card1:hover {
  transform: translateY(-20px);
}
.hi1 {
  border-left: 6px solid #6f4a37;
  font-size: 35px;
}
.lining {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}
.serv {
  border-bottom: 4px solid #6f4a37;
  font-family: "Poppins", sans-serif;
}
.servcard .card-img-top {
  height: 200px;
  object-fit: cover;
  transition: 1s;
}
.servcard {
  text-align: center;
  min-height: 480px;
  border: none;
  margin-top: 22px;
  overflow: hidden;
  cursor: pointer;
}

.c3:hover {
  transform: translateY(-20px);
}
.c1:hover {
  transform: translateY(-20px);
}
.c2:hover {
  transform: translateY(-20px);
}

.servcard .card-text {
  font-size: 15px;
}
.card-title {
  font-size: 19px;
  font-weight: bold;
}
.servcard:hover .card-title {
  color: #6f4a37;
}
.servcard,
.company {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;
}

.company {
  border: none;
  min-height: 447px;
  font-family: "Poppins", sans-serif;
  transition: 1s;
}

.compan {
  font-family: "Poppins", sans-serif;
  padding: 80px 0;
}

.servcard:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.service {
  background: url("../Image/sev.jpg");
  font-family: "Poppins", sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.servcard:hover .card-img-top {
  transform: scale(1.1);
}
.ser-para:hover {
  color: #6f4a37;
}
.ser-para {
  color: #8b776d;
}
.kk {
  padding-bottom: 35px;
}
.bi-star-fill {
  color: orange;
}
.hide {
  perspective: 20rem;
  border: none;
  background-color: transparent;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 30px;
  box-shadow: 0 5px 15px rgba(128, 0, 128, 0.308);
  border-radius: 10px !important;
  outline: none;
  color: white;
  transform-style: preserve-3d; /* Needed for 3D transform */
  transition: transform 1.5s;
  position: relative;
}

.hide::before {
  content: "";
  position: absolute;
  border-radius: 10px;
  background: linear-gradient(
    320deg,
    rgba(5, 5, 5, 0.678),
    rgba(7, 7, 7, 0.308)
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transform: rotateY(0deg);
  transform-origin: left;
  transition: transform 1.5s;
}

.hide:hover {
  transform: rotateY(30deg); /* Flips the card on hover */
}
.f1 {
  line-height: 54px;
}
.carousel-control-next-icon {
  background-color: rgb(31, 30, 30);
  padding: 20px;
  margin-top: 90px;
}
.carousel-control-prev-icon {
  background-color: rgb(31, 30, 30);
  padding: 20px;
  margin-top: 90px;
}
.mobile-the {
  margin-top: 0px !important;
}
@media (min-width: 768px) and (max-width: 991px) {
  .servcard,
  .company {
    min-height: 497px;
  }
  .f1 {
    font-size: 27px !important;
    /* margin-left: -29px; */
  }
  /* .hide {
    margin-left: -29px;
  } */
  .jp {
    position: absolute;
    left: -48px;
    top: 1px;

    border-radius: 15px;
    text-align: center;
    padding: 52px 2px;
  }
  .tex {
    position: absolute;
    width: 100%;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 992px) {
  /* .f1 {
    margin-left: -50px;
  }
  .jk {
    margin-left: -50px;
  } */
  .jp {
    position: absolute;
    left: -68px;
    top: -55px;
    border-radius: 15px;
    padding: 52px 10px;
  }
}
.kd {
  margin-top: 85px !important;
}
