@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Cabin+Sketch:wght@400;700&family=Dancing+Script:wght@400;500;600;700&family=Dosis:wght@200;300;400;500;600;700;800&family=Handjet:wght@100;200;300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@media (max-width: 600px) {
  .contabout p {
    font-size: 18px !important;
  }
  .smabout {
    margin-top: 55px;
  }
  .img-left {
    display: flex;
    justify-content: center;
  }
  .year {
    position: absolute;
    top: 53% !important;
    left: 75% !important;
    transform: translate(-47%, -40%);
    background-color: #dbac83;
    padding: 14px 20px 0px 19px !important;
    transition: 1s;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }

  .imgpara h6 {
    color: #dfc3aa;
    position: absolute;
    top: 24% !important;
    font-weight: bold;
    font-size: 32px !important;
    left: 81%;
  }
  .year h3 {
    font-size: 30px !important;
  }
  .year p {
    font-size: 12px !important;
  }
  .imgpara img {
    height: auto !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .year {
    position: absolute;
    top: 53% !important;
    left: 72% !important;
    transform: translate(-47%, -40%);
    background-color: #dbac83;
    padding: 14px 20px 0px 19px !important;
    transition: 1s;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
  .imgpara h6 {
    position: absolute;
    top: 23% !important;
    left: 78% !important;
  }
  .imgpara img {
    height: auto !important;
  }
  .paraabout {
    font-size: 12px !important;
    font-weight: bold;
  }
}
.about {
  background: url("../Image/about.png");
  background-position: center 90%;
  background-repeat: no-repeat;
  height: 80vh;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  overflow: hidden !important;
}
.abohea {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 12px 0;
}
.contabout {
  position: absolute;
  top: 57%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.contabout h1 {
  font-size: 55px !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.contabout p {
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  font-weight: 500;
}
.imgpara h6 {
  transform: rotate(-90deg);
}
.pos {
  position: relative;
}
.imgpara img {
  height: 505px;
  border-radius: 15px;
}
.imgpara h6 {
  color: #dfc3aa;
  position: absolute;
  top: 25%;
  font-weight: bold;
  font-size: 38px;
  left: 81%;
}
.year {
  position: absolute;
  top: 47%;
  left: 45%;
  background-color: #dbac83;
  padding: 15px 35px 1px 35px;
  transition: 1s;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}
.year:hover {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.year h3 {
  font-weight: bold;
  font-size: 37px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.year p {
  font-weight: 500;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.beaf {
  position: relative;
}
.beaf::before {
  content: "";
  position: absolute;
  height: 30px;
  left: 1.8%;
  width: 5px;
  background: linear-gradient(to bottom, #a7896e, #e2d8d1);
}
.beaf::after {
  content: "";
  position: absolute;
  height: 45px;
  width: 5px;
  background: linear-gradient(to bottom, #a7896e, #e2d8d1);
}
.secondAbout {
  font-family: "Poppins", sans-serif;
  overflow: hidden !important;
}
.paraabout {
  font-size: 14px;
}
