@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Cabin+Sketch:wght@400;700&family=Dancing+Script:wght@400;500;600;700&family=Dosis:wght@200;300;400;500;600;700;800&family=Handjet:wght@100;200;300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.contactus {
  background-image: url("../Image/bkt.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 100%;
  background-attachment: fixed;
  padding-bottom: 140px;
  padding-top: 45px;
}
.texting {
  border: none;
  height: 100px !important;
}
.form-control,
.texting {
  outline: none;
  border: none;
  height: 40px;
  background-color: #e8e8e8;
  border: 2px solid #e8e8e8;
  border-radius: 5px;
  padding: 10px;
  font-size: 13px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}
.form-control:focus {
  border-color: #59e73c;
}

.texting:focus {
  border-color: #59e73c;
}
.formcon {
  background-color: white;
  padding: 30px 12px;
  margin-top: -100px;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.mts {
  margin-top: 100px !important;
}
/* Hide the number input spinners */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  /* Optional: Add some padding to the input for better aesthetics */
  padding: 5px;
}
.loc {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  font-family: "Poppins", sans-serif;
}
.location {
  margin-top: 87px;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 600px) {
  .cc {
    padding: 12px 0px;
  }
}
