.services {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../Asset/Image/services.jpeg");
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 10%;
  background-attachment: fixed;
  position: relative;
}
.sevhea {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 12px 0;
  border-radius: 15px;
}
.contentService {
  position: absolute;
  top: 56%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.services h1 {
  font-weight: bold;
  font-size: 55px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.services p {
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
@media (min-width: 768px) and (max-width: 991px) {
  .kard {
    min-height: 650px !important;
  }
}
@media (max-width: 600px) {
  .kard {
    min-height: 630px;
  }
}
.kard {
  border: none;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  border-bottom: 4px solid #dbac83;
}
.kard h6 {
  font-weight: bold;
  font-size: 30px;
}
.kard p {
  line-height: 30px;
  font-weight: 500;
  color: rgb(126, 126, 126);
}
