footer {
  margin-top: 100px;
}
footer h3 {
  border-left: 5px solid #6f4a37;
  padding-left: 5px;
  margin-bottom: 15px;
}
.text1 {
  color: rgb(231, 227, 222);
  font-weight: 400;
}
