@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Cabin+Sketch:wght@400;700&family=Dancing+Script:wght@400;500;600;700&family=Dosis:wght@200;300;400;500;600;700;800&family=Handjet:wght@100;200;300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.gallery {
  background: url("../../Asset/Image/gall.png");
  height: 80vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: "Poppins", sans-serif;

  position: relative;
  background-position: center 100%;
}
.galhead {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 12px 0;
}
.contentgal {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.contentgal h1 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 55px;
}
.contentgal p {
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: 600;
}
.sk {
  height: auto !important;
  object-fit: cover;
}
.zoom-carousel h5 {
  margin-top: -176px !important;
}
@media (max-width: 600px) {
  .zoom-carousel h5 {
    margin-top: -101px !important;
  }
  .gal {
    margin-top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .zoom-carousel h5 {
    margin-top: 0px !important;
  }
}

.zoom-carousel .carousel-control-next-icon {
  display: none;
}
.zoom-carousel .carousel-control-prev-icon {
  display: none;
}

.taken,
.gal {
  color: #dbac83;
  font-weight: bold !important;
}
.typon {
  font-family: "Poppins", sans-serif;
}
