.contact {
  background: url("../Image/gall.png");
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center 100%;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}
.conhea {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 12px 0;
}
.conCon {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.conCon h1 {
  font-size: 55px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.conCon p {
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
