@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Dosis:wght@200;300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400&display=swap");
.header1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.6rem; */
  top: 0px;
  right: 0;
  z-index: -1;
  left: 0;
}
@media (min-width: 992px) {
  .header1 {
    padding: 6px;
  }
}
@media (max-width: 600px) {
  .header1 {
    padding: 0.6rem;
  }
  .nav_menu {
    display: none !important;
  }
}
header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
}
.header-bg {
  background-color: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  height: 90px;
  animation: navbaranimation 2s;
}
@keyframes navbaranimation {
  from {
    top: -30px;
  }
  to {
    top: 0px;
  }
}
.nav_menu {
  display: flex;
  list-style-type: none;
  padding-top: 15px;
}
.nav_menu li {
  padding: 0 1.5rem;
}
.nav_menu li a {
  font-size: 15px;
  text-decoration: none;
  color: #000;
  font-family: montserrat, sans-serif;
}

/* @media screen and (max-width: 1040px) {
  .nav_menu {
    width: 100%;
    display: none;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    padding-top: 45px;
  }
  .nav_menu li {
    padding: 1rem 0;
  }
} */
.logo {
  width: 28%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .td {
    display: none;
  }
  .nav_menu li a {
    font-weight: bold !important;
    font-size: 12px !important;
  }
  .nav_menu {
    padding-right: 0px !important;
    margin-right: -46px;
  }
  .logo {
    width: 44%;
    padding-left: 0px;
    margin-left: -20px;
  }
}
.nav-item {
  padding-top: 34px 34px !important;
  /* background-color: orange; */
}
